<template>
    <div>
        <st-button
            variant="primary"
            :callback="calculateTax"
            v-can:view="'taxCalculator'"
        >
            <i class="fas fa-calculator"></i>
            {{ $t('PAYMENT_REGISTRY.CALCULATE_TAX') }}
        </st-button>
        <st-button
            variant="primary"
            :callback="generateReport"
            :spinner="isReportLoading"
        >
            <i class="fas fa-file"></i>
            {{ $t('PAYMENT_REGISTRY.GENERATE_REPORT') }}
        </st-button>
        <st-button
            v-if="false"
            variant="secondary"
            :callback="addPaymentOrder"
            v-can:create="'paymentOrder'"
        >
            <i class="fas fa-credit-card"></i>
            {{ $t('PAYMENT_REGISTRY.ADD_PAYMENT_ORDER') }}
        </st-button>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'PaymentRegistryListToolbar',
        methods: {
            addPaymentOrder() {
                this.$router.push({ name: 'paymentOrder'})
            },
            calculateTax() {
                this.$router.push({ name: 'taxCalculator'});
            },
            async generateReport() {
                const params = {};
                Object.keys(this.filters)
                    .filter((el) => !['skip', 'limit', 'query', 'pagination'].includes(el))
                    .forEach(element => {
                        params[element] = this.filters[element];
                    });

                await this.generateMonthlyReport(params)
            },
            ...mapActions({
                generateMonthlyReport: 'paymentRegistry/list/generateReport'
            })
        },
        computed: {
            ...mapGetters({
               filters: 'paymentRegistry/list/reportFilters',
               loading: 'shared/loading',
            }),
            isReportLoading() {
                return this.loading["paymentRegistry/generateReport"];
            }
        },
    };
</script>
