<template>
     <st-page :title="$t('PAYMENT_REGISTRY.TITLE')">
        <loader v-if="isLoading" />
        <template #toolbar>
            <payment-registry-list-toolbar />
        </template>
        <payment-registry-list-filter
            ref="paymentRegistryListFilter"
            v-if="initLoading"
        >
            <payment-registry-list-table @update="updateList"/>
        </payment-registry-list-filter>
        <select-organisation-modal
            ref="selectOrganisationModal"
            @organisationSelected="organisationSelected"
        ></select-organisation-modal>
    </st-page>
</template>

<script>
import { mapGetters } from "vuex";
import PaymentRegistryListToolbar from "@/modules/payment-registry/components/PaymentRegistryListToolbar.vue";
import PaymentRegistryListTable from "@/modules/payment-registry/components/PaymentRegistryListTable.vue";
import PaymentRegistryListFilter from "@/modules/payment-registry/components/PaymentRegistryListFilter.vue";
import SelectOrganisationModal from '@/modules/administrator/components/modals/SelectOrganisationModal.vue';
import Roles from '@/security/roles';

export default {
    name: "PaymentRegistryList",
    components: {
        PaymentRegistryListToolbar,
        PaymentRegistryListTable,
        PaymentRegistryListFilter,
        SelectOrganisationModal
    },
    data() {
        return {
            initLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
        }),
        loadingFetch() {
            return this.loading['paymentRegistry/get'];
        },
        isLoading() {
            return this.loadingFetch;
        },
        isSuperAdmin() {
            return this.currentUser.user_type_name === Roles.values['super_admin']
        }
    },
    mounted() {
        if (this.isSuperAdmin) {
            this.initLoading = false;
            this.$refs.selectOrganisationModal.show();
        } else {
            this.initLoading = true;
        }
    },
    methods: {
        updateList() {
            this.$refs.paymentRegistryListFilter.refresh();
        },
        organisationSelected() {
            this.initLoading = true;
            this.$refs.selectOrganisationModal.hide();
        },
    },
};
</script>
