<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :serverSideSorting="true"
            @view="onView"
            @download="onDownload"
            responsive="sm"
            hover
            stateModule="paymentRegistry/list"
        >
        </st-data-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PaymentRegistryModel } from '@/modules/payment-registry/payment-registry-model';

const { fields } = PaymentRegistryModel;
export default {
    name: 'PaymentRegistryListTable',
    data() {
        return {
            presenter: PaymentRegistryModel.presenter,
            actions: [
                {
                    name: 'view',
                    icon: 'eye',
                    tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                    customIcon: false,
                    type: 'primary'
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            rows: 'paymentRegistry/list/rows',
        }),
        fields() {
            return [
                fields.client_user_personal_numeric_code.extendField({ customField: true, portalTarget: 'enable-truncate' }),
                fields.created_date.extendField({ customField: true, portalTarget: 'enable-truncate' }),
                fields.client_user_name,
                fields.amount.extendField({ bold: true }),
                fields.currency,
                fields.description,
                fields.product_identifier_type_description.extendField({ customField: true, portalTarget: 'enable-truncate' }),
                fields.client_user_email.extendField({ customField: true, portalTarget: 'enable-truncate' }),
                fields.payment_type.extendField({ labelType: true }),
                fields.status.extendField({ labelType: true }),
                fields.bt_payment_id.extendField({ customField: true, portalTarget: 'enable-truncate' }),
            ]
        }
    },
    methods: {
        onView(data) {
            if (!data?.item?.id) return;
            // Open in new tab
            let routeData = this.$router.resolve({
                name: 'applicationView',
                params: {
                    id: data.item.product_identifier,
                },
                query: { goToSection: 'applicationSummary'}
            });
            window.open(routeData.href, '_blank');
        },
        onDownload() {
            // TODO: TBD
        }
    },
};
</script>
