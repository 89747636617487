<template>
    <div>
        <st-filters-pagination
            stateModule="paymentRegistry/list"
            :filters="filters"
            :total="total"
            @change="doFilter"
            :header="$t('PAYMENT_REGISTRY.LIST.TITLE')"
            :clearButtonInPrimaryFilters="true"
        >
            <template #filters-toolbar>
                <slot name="toolbar" />
            </template>
            <slot />
        </st-filters-pagination>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { PaymentRegistryModel } from '@/modules/payment-registry/payment-registry-model';

    const { fields } = PaymentRegistryModel;

    const filterSchema = new FilterSchema([
        fields.bt_payment_id,
        fields.client_user_name,
        fields.client_user_email,
        fields.created_date,
        fields.payment_type,
        fields.status,
        fields.application_type_id
    ]);

    export default {
        name: 'PaymentRegistryListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                fields
            };
        },
        computed: {
            ...mapGetters({
               total: 'paymentRegistry/list/total',
            }),
        },
        created() {},
        methods: {
            ...mapActions({
                doFetch: 'paymentRegistry/list/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;
                this.doFetch(params);
            }
        },

    };
</script>
